export const CommunityProfileQuery = {
  getAll: (): string => {
    return `query {
      perfisComunidades(sort: "id:asc") {
        id
        nome
      }
    }`;
  }
};
