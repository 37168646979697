export const SurveyQuery = {
  getBySlug: (slug: string): string => {
    return `query{
      pesquisas(where:{slug:"${slug}"}, limit: 1){
          titulo, slug, descricao,
          emocoes {
            titulo,
            status
          },
          tags {
              texto_tag
          },
          esta_ativa
      }
    }`;
  }
};
