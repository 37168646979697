export const LinksQuery = {
  getBySlug: (slug: string): string => {
    return `query{
      bancoLinks(where:{slug:"${slug}"}, limit: 1){
          id,
          slug,
          link
      }
    }`;
  }
};
