export const SegmentsQuery = {
  getByIds: (ids: number[]) => {
    const idCondition =
      ids?.length > 0 && ` where: {id_interno: [${ids.join(',')}]}`;

    return `query{
        segmentos(sort: "id:asc"${idCondition ? ',' + idCondition : ''}){
          id,
          id_interno,
          nome,
          anos(sort: "id:asc"){
              id,
              id_interno,
              nome
          }
        }
      }`;
  }
};
