import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  SegmentModel,
  SegmentoLivModel
} from 'src/app/core/models/segment.model';
import { ApiLiv } from 'src/app/core/services/api/api-liv.service';

import { CommunityProfileQuery } from './graphql/community-profile.gql';
import { LinksQuery } from './graphql/links.gql';
import { SegmentsQuery } from './graphql/segments.gql';
import { SurveyQuery } from './graphql/survey.gql';
import { KanttumUserProgressModel } from '../../../../core/models/kanttum/kanttum-user-progress.model';
import { AccessProfileModel } from '../../../models/access-profile.model';
import { CommunityProfileModel } from '../../../models/community-profile';
import { LinkModel } from '../../../models/link.model';
import { SurveyModel } from '../../../models/survey-model';
import { UserRegistrationUpdateModel } from '../../../models/user-registration-update.model';

@Injectable({
  providedIn: 'root'
})
export class ApiLivRequestsService {
  constructor(private apiLiv: ApiLiv) {}

  getAllSegments(): Observable<SegmentModel[]> {
    const gql = SegmentsQuery.getByIds([]);

    return this.apiLiv
      .graphql<{ segmentos: SegmentModel[] }>(gql)
      .pipe(map((data) => data.segmentos));
  }

  getSegments(): Observable<SegmentoLivModel[]> {
    return this.apiLiv.get<SegmentoLivModel[]>('/Pessoa/segments');
  }

  getCourseProgress(course: string): Observable<KanttumUserProgressModel> {
    const params = new HttpParams().set('course', course);

    return this.apiLiv.post<KanttumUserProgressModel>(
      '/Kanttum/UserProgress',
      {},
      {
        params
      }
    );
  }

  getSurvey(slug: string): Observable<SurveyModel> {
    const gql = SurveyQuery.getBySlug(slug);

    return this.apiLiv
      .graphql<{ pesquisas: SurveyModel[] }>(gql)
      .pipe(map((data) => data.pesquisas[0]));
  }

  checkUserHasActiveSurvey(slug: string): Observable<boolean> {
    return this.apiLiv.get(`/survey?slug=${slug}`);
  }

  getAccessProfiles(
    excludeAdminProfile = true
  ): Observable<AccessProfileModel[]> {
    return this.apiLiv.get<AccessProfileModel[]>(
      `/PerfilAcesso?selectable=${String(excludeAdminProfile)}`
    );
  }

  updateUserRegistration(data: UserRegistrationUpdateModel): Observable<void> {
    return this.apiLiv.put<void>('/Usuario/RegistrationUpdate', data);
  }

  getLink(slug: string): Observable<LinkModel> {
    const gql = LinksQuery.getBySlug(slug);

    return this.apiLiv
      .graphql<{ bancoLinks: LinkModel[] }>(gql)
      .pipe(map((data) => data.bancoLinks[0]));
  }

  getCommunityProfiles(): Observable<CommunityProfileModel[]> {
    const gql = CommunityProfileQuery.getAll();

    return this.apiLiv
      .graphql<{ perfisComunidades: CommunityProfileModel[] }>(gql)
      .pipe(map((data) => data.perfisComunidades));
  }

  checkUserHasAcceptedTerms(): Observable<boolean> {
    return this.apiLiv.get<boolean>('/Usuario/AcceptTerms');
  }

  acceptTerms(): Observable<void> {
    return this.apiLiv.put<void>('/Usuario/AcceptTerms', {});
  }
}
